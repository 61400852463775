import React, { useEffect, useRef, useState } from "react";
import "../../FrontEndPages/FrontEnd.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Backdrop,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Modal,
  Snackbar,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function HomeMiddleBck(props) {
  const [calculateModalOpen, setCalculateModalOpen] = useState(false);
  const [commingSoonModalOpen, setCommingSoonModalOpen] = useState(false);
  const [employerChecked, setEmployerChecked] = useState(false);
  const [propertyChecked, setPropertyChecked] = useState(false);
  const [bothChecked, setBothChecked] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [calculateOption, setCalculateOption] = useState("");
  const [opportunityType, setOpportunityType] = useState("");

  const calculateModalClose = () => setCalculateModalOpen(false);
  const commingSoonModalClose = () => setCommingSoonModalOpen(false);

  const getCalculateOption = (type) => {
    setEmployerChecked(false);
    setPropertyChecked(false);
    setBothChecked(false);
    setOpportunityType('')
    if (type === "employer") {
      setEmployerChecked(true);
      setOpportunityType('Employer');
    } else if (type === "property") {
      setPropertyChecked(true);
      setOpportunityType('Property Owner');
    } else {
      setBothChecked(true);
      setOpportunityType('Both Employer and Property Owner');
    }
    setCalculateOption(type);
  };

  function validateEmail() {
    var email = document.getElementById("emailInput").value;
    var lblError = document.getElementById("lblError");
    // setIsInvalidEmail(true);
    if (lblError) {
      lblError.innerHTML = "";
    }
    var expr =
      /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (!expr.test(email)) {
      lblError.innerHTML = "Invalid email address";
      setIsInvalidEmail(false);
    }
  }

  const calculatePopup = () => {
    setCalculateModalOpen(true);
    setEmployerChecked(false);
    setPropertyChecked(false);
    setBothChecked(false);
    if (document.getElementById("lblError")) {
      document.getElementById("lblError").innerHTML = "";
    }
  };
  const commingSoonPopup = () => {
    setCommingSoonModalOpen(true)
  }

  const calculateNowBtnClick = () => {
    let gmgLinkSplitArr = props.gmglink.split("/");
    let gmgLinkSplitID = gmgLinkSplitArr.slice(
      Math.max(gmgLinkSplitArr.length - 1, 0)
    );
    validateEmail();
    if (document.getElementById("lblError").innerHTML === "") {
      if (calculateOption !== "") {
        const reqData = {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify({
            email: document.getElementById("emailInput").value,
            opportunity_type:opportunityType,
            domain_id: process.env.REACT_APP_WEBSITE_ID
              ? process.env.REACT_APP_WEBSITE_ID
              : "",
          }),
        };
        fetch(
          process.env.REACT_APP_API_URL + "calendar/get-opportunities",
          reqData
        )
          .then((results) => results.json())
          .then((data) => {
            console.log("data", data);
            if (data.status === "success") {
              setCalculateModalOpen(false);
              setOpenSnackbar(true);
              setSnackbarMessage(data.results);
              setTimeout(() => {
                if (
                  calculateOption === "employer" ||
                  calculateOption === "both"
                ) {
                  window.open(`${props.gmglink}`);
                } else {
                  window.open(
                    `https://propertytaxcredits.com/activation/${gmgLinkSplitID[0]}`
                  );
                }
              }, 500);
            }
          });
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage("Please choose one option!");
      }
    } else {
      // setOpenSnackbar(true);
      // setSnackbarMessage("Email Invalid!");
    }
  };
  const closeSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <div className="home-middle-bck">
        <div className="commonWidth">
          <h1 className="middle_top_heading">
            Thousands to Millions of dollars paid to Employers and Property
            Owners
          </h1>
          <div className="home-middle-inner_bck">
            <div className="middleblock_flex_wrp">
              <div className="middle_left_block">
                <div className="middle_logo_wrapper">
                  <img src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/aspire_middleblock_logo.webp" />
                </div>
                <div className="middle_lef_title_block">
                  <h1>
                    Employer or Property Owner? In less than 10 minutes see what
                    you are owed in Government Tax Credits across 100+ programs
                    including ERC!
                  </h1>
                  <h3>
                    Eligible companies may get up to $26,000 per EMPLOYEE - and
                    that’s only ONE possible tax credit.
                  </h3>
                  {/* <h3>
                    <span>Affected by COVID-19?</span> Our software and program
                    sources tax credits that are missed by many employers. Don’t
                    leave money on the table - find out much your business can
                    save in minutes!
                  </h3> */}
                </div>
              </div>
              <div className="middle_right_block">
                <div className="right_top_block">
                  <div className="right_top_left_title">
                    <h2>
                      IN PARTNERSHIP WITH GMG, THE MOST POWERFUL GOVERNMENT TAX
                      CREDIT SOFTWARE AND EXCLUSIVE INCENTIVE SERVICE IN THE
                      COUNTRY.
                    </h2>
                  </div>
                  <div className="right_top_right_logo">
                    <img src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/middleblock_right_round_logo.webp" />
                  </div>
                </div>


                
               
                  {props.gmglink && props.gmglink !== "" ? (
                     <div  onClick={calculatePopup} className="quality_block">
                    <h2 >
                      <a ><span className="click_linked">CLICK HERE</span> TO QUICKLY SEE HOW MUCH MONEY YOU QUALIFY FOR!</a>
                    </h2>
                    </div>
                  ) : (
                    <div onClick={commingSoonPopup}  className="quality_block">
                    <h2>
                      <a ><span className="click_linked">CLICK HERE</span> TO QUICKLY SEE HOW MUCH MONEY YOU QUALIFY FOR!</a>
                    </h2>
                    </div>
                  )}
              
                <div className="middle_bottom_block">
                  <div className="taxcredit_title">
                    <h2>Just a few of the tax credit opportunities include:</h2>
                  </div>
                  <div className="blocklist_wrapper">
                    <p>WOTC</p>
                    <p>Manufacturing Payroll Credits</p>
                    <p>Technical Job Tax Credits</p>
                    <p>Enterprise Zone Credits</p>
                    <p>Startup Tax Credits</p>
                    <p>Small Business Tax Credits</p>
                  </div>
                  <h2 className="bottom_bold_text">
                    Competitors: 20% to 30% ERC only | GMG: 15% across all 100+
                    Tax Credits!
                  </h2>
                </div>
              </div>
            </div>
            <div className="middle_btm_text">
              {/* <h3>
              <span>Affected by COVID-19?</span> Our software and program
              sources tax credits that are missed by many employers. Don’t
              leave money on the table - find out much your business can
              save in minutes!
            </h3> */}
              <h3>
                Our software and program sources thousands of dollars in tax
                credits that your company deserves!
              </h3>
            </div>
          </div>
          <div className="middleblock_bottom">
            <h2>Don’t take our words for it. Our track record says it all; </h2>
            <div className="benefit_block">
              <div className="benefit_flex_block">
                <div className="inner_container">
                  <p>Office / Warehouse Building Benefit: $40,400</p>
                </div>
                <div className="inner_container">
                  <p>Apartment Complexes Benefit: $1,344,183</p>
                </div>
                <div className="inner_container">
                  <p>Retail Benefit: $758,388</p>
                </div>
                <div className="inner_container">
                  <p>Software IT Company Benefit: $6,765,589</p>
                </div>
                <div className="inner_container">
                  <p>Restaurant Benefit: $2,525,000</p>
                </div>
                <div className="inner_container">
                  <p>Home Health Care Company Benefit: $3,650,250</p>
                </div>
                <div className="inner_container">
                  <p>Car Dealership Benefit: $6,000,000</p>
                </div>
              </div>
            </div>
          </div>
          
            {props.gmglink && props.gmglink !== "" ? (
              <div onClick={calculatePopup} className="qualify_block">
              <h1>
                <a><span className="click_linked">CLICK HERE</span> TO QUICKLY SEE HOW MUCH MONEY YOU QUALIFY FOR!</a>
              </h1>
              </div>
            ) : (
              <div onClick={commingSoonPopup} className="qualify_block">
              
              <h1><a><span className="click_linked">CLICK HERE</span> TO QUICKLY SEE HOW MUCH MONEY YOU QUALIFY FOR!</a></h1>
              </div>
            )}
          </div>
        </div>
     

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modalPop"
        open={calculateModalOpen}
        onClose={calculateModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <>
          <div className="exit-intent-popup">
            <div className="newsletter">
              <span className="close" onClick={calculateModalClose}>
                x
              </span>
              <div className="contentDiv">
                <div className="contentDiv_heading">
                  <span className="aspireLogo">
                    <img src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/aspire_blue_logo.webp" />
                  </span>
                  <h2> In partnership with </h2>
                  <img src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/middleblock_right_round_logo.webp" />
                </div>
                <div className="calculateCheckboxOptions">
                  <h3>Please select one of the following:</h3>
                  <Box sx={{ display: "flex" }}>
                    <FormControl
                      sx={{ m: 3 }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={employerChecked}
                              onChange={() => getCalculateOption("employer")}
                              name="employer"
                            />
                          }
                          label="Employer"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={propertyChecked}
                              onChange={() => getCalculateOption("property")}
                              name="property"
                            />
                          }
                          label="Property Owner"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={bothChecked}
                              onChange={() => getCalculateOption("both")}
                              name="both"
                            />
                          }
                          label="Both Employer and Property Owner"
                        />
                      </FormGroup>
                    </FormControl>
                  </Box>
                </div>
                <div className="emailInputDiv">
                  Enter your email address :{" "}
                  <input
                    type="email"
                    placeholder="Type Your Email"
                    id="emailInput"
                  />
                  {!isInvalidEmail ? <span id="lblError"></span> : ""}
                </div>
                <Button onClick={calculateNowBtnClick} className="calculateBtn">
                  Calculate Now
                </Button>
              </div>
            </div>
          </div>
        </>
      </Modal>



      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modalPop"
        open={commingSoonModalOpen}
        onClose={commingSoonModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <>
          <div className="exit-intent-popup">
            <div className="newsletter">
              <span className="close" onClick={commingSoonModalClose}>
                x
              </span>
              <div className="contentDiv">
                <div className="calculateCheckboxOptions"><h4 className="commingSoonText">Comming Soon...</h4></div>
              </div>
            </div></div>
        </>
      </Modal>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={closeSnackbar}
        message={snackbarMessage}
        key="bottomcenter"
      />
    </>
  );
}

export default HomeMiddleBck;
