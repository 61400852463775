import React, { useEffect, useRef, useState } from "react";
import { Form } from "../../../form/Form";
import "../../FrontEndPages/FrontEnd.css";
import StateArray from "../../../assets/json/state";
import { useDispatch, useSelector } from "react-redux";
import store from "../../../store";
import { useNavigate } from "react-router-dom";

import "./HomeFormBlck.css";
import {
  Alert,
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { addBrowserReport, setLeadinfo } from "../../Login/loginReducer";
import { useCookies } from "react-cookie";
import {
  resetTempFormUpdateData,
  setTempFormUpdateData,
} from "../../../form/formReducer";
import { fetchCalenderEvents } from "../../Calender/calenderReducer";

function HomeFormBlck() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log("StateArray",StateArray);

  const [locationArry, setLocationArry] = useState([]);
  const [loading, setloading] = useState(false);
  const [concurrencyhandleflag, setConcurrencyhandleflag] = useState(false);
  const [allServicesElseAll, setAllServicesElseAll] = useState(false);
  const [ipinfo, setIpinfo] = useState(null);

  const formSubmittionstate = useSelector((state) =>
    state.formSlice.formSubmissionState.leadform
      ? state.formSlice.formSubmissionState.leadform
      : 0
  );
  const formInputData = useSelector((state) =>
    state.formSlice.formData.leadform ? state.formSlice.formData.leadform : null
  );

  const websiteInformation = useSelector((state) =>
    state.loginSlice.websiteinfo ? state.loginSlice.websiteinfo : null
  );

  useEffect(() => {
    let Newarr = [];
    if (StateArray && StateArray.length > 0) {
      for (let i in StateArray) {
        Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
      }
      setLocationArry(Newarr);
    }
  }, []);

  useEffect(() => {
    // console.log("formSubmittionstate", formSubmittionstate, formInputData);

    if (formSubmittionstate && formSubmittionstate === 4) {
      addapicall(formInputData);
    }

    // if(formSubmittionstate && formSubmittionstate==1){
    //   setloading(true)

    // }
    // if(formSubmittionstate && formSubmittionstate==2){
    //   navigate('/calender')
    //   setloading(false)
    // }
    // if(formSubmittionstate && formSubmittionstate==0){
    //   setloading(false)

    // }
  }, [formSubmittionstate]);
 
  useEffect(() => {
    if (formInputData && formInputData.formId === "leadform") {
      let valueArr = []
      if (formInputData.service_interests && formInputData.service_interests.length > 0) {
        // If All option clicked for checked
        if (formInputData.service_interests.includes("All") && !concurrencyhandleflag && !allServicesElseAll) {
          valueArr = [
            "Credit Card Processing",
            "Utilities",
            "Wireless",
            "Workers Comp",
            "Laundry & Linen",
            "All",
          ]
          setConcurrencyhandleflag(true);
          getFormUpdateData(valueArr);
        }
        // If without All option clicked
        if (formInputData.service_interests.includes("Credit Card Processing") &&
          formInputData.service_interests.includes("Utilities") &&
          formInputData.service_interests.includes("Wireless") &&
          formInputData.service_interests.includes("Workers Comp") &&
          formInputData.service_interests.includes("Laundry & Linen") &&
          !formInputData.service_interests.includes("All") && !concurrencyhandleflag && !allServicesElseAll) {
          valueArr = [
            "Credit Card Processing",
            "Utilities",
            "Wireless",
            "Workers Comp",
            "Laundry & Linen",
            "All",
          ]
          setAllServicesElseAll(true);
          setConcurrencyhandleflag(true);
          getFormUpdateData(valueArr);
        }
        // If All checkbox false & other true
        if (!formInputData.service_interests.includes("All") && concurrencyhandleflag && !allServicesElseAll) {
          valueArr = [];
          setConcurrencyhandleflag(false);
          getFormUpdateData(valueArr);
          console.log("formInputData 3 : If All checkbox false & other true")
        }
        // If All checkbox true & other false
        if (formInputData.service_interests.length < 6 && concurrencyhandleflag) {
          if (formInputData.service_interests.includes("All")) {
            let ser = formInputData.service_interests;
            valueArr = ser.filter(item => item !== 'All');
            console.log("formInputData 3 4", valueArr)
            setConcurrencyhandleflag(false);
            setAllServicesElseAll(false);
            getFormUpdateData(valueArr)
            console.log("formInputData 4 : If All checkbox true & other false")
          };
        };
        // IF All option set to unchecked forcefully
        if (concurrencyhandleflag && allServicesElseAll) {
          if (formInputData.service_interests.length === 5) {
            if (!formInputData.service_interests.includes("All")) {
              valueArr = []
              getFormUpdateData(valueArr);
            }
          };
        }
      };
    }
  }, [formInputData]);



  function getFormUpdateData(values) {
    // console.log("formInputData 3", values)
    dispatch(
      setTempFormUpdateData({
        formId: "leadform",
        fieldName: "service_interests",
        value: values
      }))
  }

  let currTime = new Date().getTime();
  let dayAfterTomorrow = currTime + 48 * 3600 * 1000;
  useEffect(() => {
    if (websiteInformation) {
      fetch("https://ipinfo.io/?format=json&token=9797c42b93078a")
        .then((results) => results.json())
        .then((data) => {
          // console.log("<<<<<<<<<<< data goes here >>>>>>>>>", data);
          if (data) {
            const requestData = {
              website_information: websiteInformation,
              ipinfo: data,
            };
            dispatch(addBrowserReport(requestData));
          }
        });
    }
  }, [websiteInformation]);

  let addForm = [
    //////////////////////////////////// Text Input Event Title ///////////////////////////////
    {
      id: 0,
      label: "First Name",
      name: "firstname",
      className: "formGroup eventTitle countDiv__column--col12",
      type: "text",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        maxLength: "MaxLength length 20 allowed",
      },
      defaultValue: "",
    },
    {
      id: 1,
      label: "Last Name",
      name: "lastname",
      className: "formGroup eventTitle countDiv__column--col12",
      type: "text",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        maxLength: "MaxLength length 20 allowed",
      },
      defaultValue: "",
    },
    {
      id: 2,
      label: "Phone No",
      name: "phone",
      className: "formGroup countDiv__column--col12",
      type: "text",
      inputType: "phone",
      required: "This Field is Required",
      rules: { required: true, minLength: 10 },
      errorMessage: {
        maxLength: "minLength is 10 allowed",
      },
    },
    {
      id: 3,
      label: "Email",
      name: "email",
      className: "formGroup countDiv__column--col12",
      type: "text",
      rules: {
        required: true,
        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      },
      errorMessage: {
        required: "This Field is Required",
        pattern: "Enter a valid email",
      },
    },
    {
      id: 9,
      label: "Website",
      name: "website",
      className: "formGroup countDiv__column--col12",
      type: "text",

    },
    {
      id: 4,
      label: "State",
      name: "state",
      className: "formGroup eventTitle state countDiv__column--col12",
      type: "select",
      // checkbox: true,
      other: true,
      values: locationArry,
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        maxLength: "MaxLength length 20 allowed",
      },
      defaultValue: "",
    },
    {
      id: 5,
      label: "City",
      name: "city",
      className: "formGroup eventTitle countDiv__column--col12",
      type: "text",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        maxLength: "MaxLength length 20 allowed",
      },
      defaultValue: "",
    },

    {
      id: 6,
      label: "Employee Size",
      name: "employee_size",
      className: "formGroup eventTitle countDiv__column--col12",
      type: "text",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        maxLength: "MaxLength length 20 allowed",
      },
      defaultValue: "",
    },
    {
      id: 7,
      label: "Years in Business",
      name: "years_in_business",
      className: "formGroup eventTitle countDiv__column--col12",
      type: "text",
      // inputType: "number",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        maxLength: "MaxLength length 20 allowed",
      },
      defaultValue: "",
    },
    {
      id: 8,
      heading: "Check Service Interests",
      name: "service_interests",
      className: "multipleCheckbox",
      hint: "In months",
      type: "multipleCheckbox",
      values: [
        { key: "All", val: "All" },
        { key: "Credit Card Processing", val: "Credit Card Processing" },
        { key: "Utilities", val: "Utilities" },
        { key: "Wireless", val: "Wireless" },
        { key: "Workers Comp", val: "Workers Comp" },
        { key: "Laundry & Linen", val: "Laundry & Linen" },
      ],
      // rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid",
      },
    },
  ];

  const addapicall = async (dataccc) => {
    let data = dataccc;

    console.log("websiteInformation data", data);
    if (
      data &&
      data.service_interests !== undefined &&
      data.service_interests.length > 0 &&
      data.service_interests.includes("All")
    ) {
      let ser = data.service_interests;
      ser = ser.filter(item => item !== 'All')
      // let index = ser.indexOf('All');
      // if (index > -1) {
      //   // only splice array when item is found
      //   ser.splice(index, 1); // 2nd parameter means remove one item only
      // }
      console.log("websiteInformation data", ser);
      data = { ...data, service_interests: ser }
      // // data.service_interests = ser
      // Object.preventExtensions(data.service_interests);

    }
    console.log("websiteInformation data", data);
    // console.log("data0--0",data);

    setloading(true);
    if (data != null) {
      // data.website_information']=websiteInformation
      const requestOptions = {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(data),
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + "api/submit-intake-form",
        requestOptions
      );
      // console.log("++++response+++++++",response);

      const respdata = await response.json();
      // console.log("++++cv+++++++", respdata);

      if (respdata && respdata.status === "success") {
        dispatch(setLeadinfo(respdata.results));
        navigate(`/calendar/${respdata.results._id}`);
        setloading(false);
      }
    }
  };

  store.subscribe(() => {
    const storeData = store.getState();
    if (
      storeData.loginSlice &&
      storeData.loginSlice.websiteinfo &&
      Object.keys(storeData.loginSlice.websiteinfo).length > 0
    ) {
    }
    // console.log("storeData login", storeData.loginSlice)
  })

  const formData = {
    id: "leadform",
    api_url: process.env.REACT_APP_API_URL,
    // endPoint: "api/submit-intake-form",
    // urlPathOnSuccessfulFormSubmission: "/Calender",
    formButtonClass: "submitbtnsection",
    fields: addForm,
    formAdditionalSubmissionData: {
      website_information: websiteInformation,
      //   useremail: `${item.email}`,
      //   id: params.slotID ? params.slotID : null
    },
  };
  return (
    <>
      <div className="formSction_maimWrp">
        <div className="commonWidth">
          <div className="formSction_subWrp">
            <div className="leftSection_texts">
              <img
                src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/Aspirelogoform_con.webp"
                className="leftSection_texts_logo"
              ></img>

              <h2>
                WE GET YOU REFUNDS AND ONGOING SAVINGS, OR YOU PAY US NOTHING!
              </h2>
              <p>
                Qualify for immediate high-dollar refunds and ongoing savings
                from services you are already using! Many of our clients get
                back as much as six figures after a short discussion with our
                consultants!
              </p>
              <h3>
                THERE IS MONEY HIDING IN THE SERVICES YOU ARE ALREADY SPENDING
                MONEY ON.
              </h3>

              <div className="leftSection_texts_con1">
                <div className="leftSection_texts_con1_BG">
                  <label>
                    Please fill out the form and schedule to talk with our experts!
                  </label>
                </div>
              </div>
              <div className="leftSection_texts_con2">
                <div className="leftSection_texts_con2_BG">
                  <label>
                    LOOK BELOW FOR EXAMPLES OF WHAT WE HAVE DONE FOR OUR
                    CUSTOMERS!
                  </label>
                </div>
              </div>
            </div>
            <div className="formWrapMain">
              <div className="formWrapperMain">
                <div className="formHeader">
                  <h2>SPEAK WITH AN ASPIRE</h2>
                  <h2>EXPENSE REDUCTION AND </h2>
                  <h2>REFUND EXPERT RIGHT AWAY</h2>
                </div>
                <Form formData={formData} />

                <div>
                  {loading && <LinearProgress sx={{ margin: "5px" }} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeFormBlck;
