import React, { useEffect, useRef, useState } from "react";
import "../FrontEndPages/FrontEnd.css";
import ReactPlayer from "react-player";
import HomeMiddleBck from "./HomeComponents/HomeMiddleBck";
import HomeFormBlck from "./HomeComponents/HomeFormBlck";
import HomeUtilityBlck from "./HomeComponents/HomeUtilityBlck";
import HomeExpenseBlck from "./HomeComponents/HomeExpenseBlck";
import { useDispatch, useSelector } from "react-redux";
import Iframe from "react-iframe";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import store from "../../store";
import { logOut, setloginRoute } from "../Login/loginReducer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import LoginIcon from "@mui/icons-material/Login";
import CloseIcon from "@mui/icons-material/Close";
import ExpenseReductionBlck from "./HomeComponents/HomeExpensereductionBlck";

function Home() {
  //   const formSubmittionstate = useSelector((state) => state.formSlice.formSubmissionState? state.formSlice.formSubmissionState:null);

  //   useEffect(()=>{
  //     console.log("formSubmittionstate",formSubmittionstate);
  // },[formSubmittionstate])
  const [webTopBanner, setWebTopBanner] = useState(null); //***** website content setting****//
  const [webFooterBanner, setWebFooterBanner] = useState(null); //***** website content setting****//
  const [gmgLink, setGmgLink] = useState(null); //***** website content setting****//
  const [videoid] = useState("mog9IyT0CPU");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const spalizedsec = useRef(null);
  const expensesec = useRef(null);

  const param = useParams();

  // --- Feched website content on load ----
  const websiteContent = useSelector((state) =>
    state.loginSlice.websiteContent ? state.loginSlice.websiteContent : null
  );

  // --- Feched website info on load ----
  const websiteInfo = useSelector((state) =>
    state.loginSlice?.websiteinfo ? state.loginSlice.websiteinfo : null
  );

  // console.warn("<<<<<<< websiteInfo >>>>>>", websiteInfo)

  useEffect(() => {
    if (websiteInfo?.gmg_link !== "" && websiteInfo?.gmg_link !== null) {
      setGmgLink(websiteInfo?.gmg_link);
    }
  }, [websiteInfo]);

  // console.warn("<<<<<<<< websiteContent from home page >>>>>", websiteContent)

  useEffect(() => {
    // console.log("<<<<<<< websiteContent >>>>>", websiteContent)

    if (
      websiteContent !== null &&
      websiteContent !== undefined &&
      websiteContent !== "" &&
      websiteContent.length !== 0
    ) {
      for (let i = 0; i < websiteContent.length; i++) {
        if (
          websiteContent[i].content_slug == "top_banner" &&
          websiteContent[i].status !== "inactive"
        ) {
          // console.log("<<<<<<< Website Top Banner  >>>>>", websiteContent[i]);
          setWebTopBanner(websiteContent[i].content);
        }

        if (
          websiteContent[i].content_slug == "footer_banner" &&
          websiteContent[i].status !== "inactive"
        ) {
          // console.log("<<<<<<< Website Footer Banner >>>>>", websiteContent[i].content);
          setWebFooterBanner(websiteContent[i].content);
        }
      }
    }
  }, [websiteContent]);

  let isLoggedIn = useSelector((state) => state.loginSlice?.isLoggedIn);

  const routeToLogin = () => {
    console.log("login");
    dispatch(setloginRoute(true));
    navigate("/login");
  };

  const [openTerms, setOpenTerms] = React.useState(false);

  const handleClickOpenTerms = () => {
    setOpenTerms(true);
  };

  const handleCloseTerms = () => {
    setOpenTerms(false);
  };

  const [openPrivacy, setOpenPrivacy] = React.useState(false);

  const handleClickOpenPrivacy = () => {
    setOpenPrivacy(true);
  };

  const handleClosePrivacy = () => {
    setOpenPrivacy(false);
  };
  const schollsec = (val) => {
    if (val && val == "Specialized") {
      spalizedsec.current.scrollIntoView({ behavior: "smooth" });
    } else {
      expensesec.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useState(() => {
    if (param && param.opportunities) {
      setTimeout(() => {
        expensesec.current.scrollIntoView({ behavior: "smooth" });
      }, 200);
    }
  }, [param]);
  return (
    <>
      <div className="HomeMain_container">
        <div className="HomeBanner_container">
          <div className="commonWidth">
            <div className="homeBanner_Main">
              {/* ---- TopBanner ----- */}
              {webTopBanner !== null && webTopBanner !== "" ? (
                <div dangerouslySetInnerHTML={{ __html: webTopBanner }} />
              ) : (
                <img src={process.env.REACT_APP_BANNER} alt="" />
              )}

              

              {/* ------------------ */}

              <div className="bannerMiddle">
                <div className="videoWrapper">
                  <div className="videoWrapper_iframe">
                    {/* ================================================================================================ */}
                    {process.env.REACT_APP_VIDEO_THUMB &&
                    process.env.REACT_APP_VIDEO_THUMB != "" ? (
                      <ReactPlayer
                        className="ReactPlayerBG"
                        width="100%"
                        height="100%"
                        url={process.env.REACT_APP_VIDEO_URL}
                        light={process.env.REACT_APP_VIDEO_THUMB}
                        playing
                        controls
                      />
                    ) : (
                      <>
                        <img src={process.env.REACT_APP_GAPHIC} />
                      </>
                    )}

                    {/*              <Iframe url={'http://www.youtube.com/embed/${videoid}?rel=0'}
                  width="100%"
                  id="myId"
                  className="myClassname"
                  height="100%"
                  /> */}

                    {/* ================================================================================================ */}
                  </div>
                </div>
                <div className="rightcontent_Wrapper">
                  <div className="asPireStep_Block">
                    <div className="asPireStep_BlockWrp">
                      <div className="asPireLogo">
                        <img
                          src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/AsPire_Logo1.png"
                          alt=""
                        />
                      </div>
                      <div className="asPireText">
                        <h1>Aspire</h1>
                        <h3>Specialized Tax Savings </h3>
                        <p>
                          In less than 10 minutes see what your company is owed
                          in Government Tax Credits acoss 100 different programs
                          including ERC!
                        </p>

                        <span className="stePWrp">STEP 1</span>
                      </div>
                    </div>
                    <span
                      className="arrowdownnforscroll"
                      onClick={() => schollsec("Specialized")}
                    ></span>
                  </div>
                  <div className="asPireStep_Block">
                    <div className="asPireStep_BlockWrp">
                      <div className="asPireLogo">
                        <img
                          src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/AER_green.png"
                          alt=""
                        />
                      </div>
                      <div className="asPireText">
                        <h1>Aspire</h1>
                        <h3> Expense Reduction </h3>
                        <p>
                          Our consultants will optimize your business expenses
                          for huge returns on past expenses and future savings.
                        </p>

                        <span className="stePWrp">STEP 2</span>
                      </div>
                    </div>
                    <span
                      className="arrowdownnforscroll"
                      onClick={() => schollsec("Expense")}
                    ></span>
                  </div>
                </div>
              </div>
              <div className="bannerBottom">
                <div className="bannerBottomInner">
                  <p>
                    WITH JUST TWO SIMPLE STEPS, WE PUT MONEY BACK IN YOUR
                    COMPANY'S POCKET!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ====================HomeMiddleBlock=========================== */}
        <div ref={spalizedsec}>
          <HomeMiddleBck gmglink={gmgLink} />
        </div>
        {/* ====================HomeMiddleBlock=========================== */}

        <div className="bottomAllSection_main" ref={expensesec}>
          <HomeFormBlck />
          <HomeUtilityBlck />
          {/* <HomeExpenseBlck /> */}
          <ExpenseReductionBlck />

          <div className="bottomFooter_Block">
            <div className="commonWidth">
              {webFooterBanner !== null && webFooterBanner !== "" ? (
                <div dangerouslySetInnerHTML={{ __html: webFooterBanner }} />
              ) : (
                <div>
                  <img src={process.env.REACT_APP_FOOTER} alt="" />
                </div>
              )}              





              {/* <img src={process.env.REACT_APP_FOOTER} alt="" /> */}

              {/* ------ End Section -------- */}
            </div>
          </div>
        </div>

        <div className="fooTerCopyright">
          <div className="commonWidth">
            <div className="footerSection">
              {/* <p>Terms & Conditions Privacy Policy Copyright@2022</p> */}

              <div className="footerblock">
                <Link onClick={handleClickOpenTerms}>Terms & Conditions </Link>
                <span>|</span>
                <Link onClick={handleClickOpenPrivacy}>Privacy Policy</Link>
                <span>Copyright@2022</span>
              </div>

              <Dialog
                open={openTerms}
                onClose={handleCloseTerms}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="footerModal"
              >
                <Button onClick={handleCloseTerms} className="closeModalBtn">
                  {" "}
                  <CloseIcon />{" "}
                </Button>
                <DialogTitle>Terms & Conditions</DialogTitle>

                <DialogContent>
                  <img
                    src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/Bizopp_mainLogo.png"
                    className="modallogo"
                  ></img>
                  <div className="comingsoonText">coming soon</div>
                </DialogContent>
              </Dialog>

              <Dialog
                open={openPrivacy}
                onClose={handleClosePrivacy}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="footerModal"
              >
                <Button onClick={handleClosePrivacy} className="closeModalBtn">
                  <CloseIcon />
                </Button>
                <DialogTitle>Privacy Policy</DialogTitle>

                <DialogContent>
                  <img
                    src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/Bizopp_mainLogo.png"
                    className="modallogo"
                  ></img>
                  <div className="comingsoonText">coming soon</div>
                </DialogContent>
              </Dialog>

              {/* <p>{JSON.stringify(process.env.REACT_APP_LOGIN_SHOW,null,2)}</p> */}
            {process.env.REACT_APP_LOGIN_SHOW && process.env.REACT_APP_LOGIN_SHOW!== undefined && process.env.REACT_APP_LOGIN_SHOW!==''? <div className="footer_login_btn">
                {isLoggedIn ? (
                  <>
                    <Button
                      sx={{ bgcolor: "#04bfff", borderRadius: "20px" }}
                      variant="contained"
                      endIcon={<LoginIcon />}
                      onClick={() => {
                        store.dispatch(logOut());
                        const cookies = new Cookies();
                        cookies.remove("userInfo", { path: "/" });
                        cookies.remove("token", { path: "/" });
                        navigate("/", { replace: true });
                      }}
                    >
                      <a>Log Out</a>
                    </Button>
                  </>
                ) : (
                  <Button
                    sx={{ bgcolor: "#04bfff", borderRadius: "20px" }}
                    variant="contained"
                    endIcon={<LoginIcon />}
                    onClick={() => routeToLogin()}
                  >
                    Log In
                  </Button>
                )}
              </div>:null }  
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
